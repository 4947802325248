import { GuildRosterSearch } from 'components/guildroster/guildroster';
import './charactersearch.css';

const CharacterSearch = () => {
	return( 
		<div>
			<GuildRosterSearch />
		</div>
	);
};
export default CharacterSearch;
